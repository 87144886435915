import { ColorHex } from '#constants';
import { useContrast } from '@lib/hooks';

import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import type { SideBySideHeroStyles as Props } from './types';

export const useStyles = ({
  backgroundColor,
  hasAnimation,
}: Props): typeof Styles => {
  const color = ColorHex[backgroundColor || 'White'];
  const { text } = useContrast(backgroundColor);

  const Styles = {
    ROOT: {
      backgroundColor: color,
      color: text,
      contain: 'layout',
    } as TSO,

    SUPERHEADING_RATING: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      '& small': {
        paddingInlineEnd: 1,
      },
    } as TSO,

    LOGO: {
      marginBlockStart: 4,
    } as TSO,

    CONTENT: {
      display: 'flex',
      flexDirection: hasAnimation ? ['column', null, 'row'] : ['column', 'row'],
    } as TSO,

    CONTAINER: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1,
      paddingInlineStart: [1, 2, 6],
      paddingInlineEnd: [1, 2, 6],
      paddingBlockStart: 6,
      paddingBlockEnd: 6,

      '& h1': {
        marginBlockStart: 2,
      },

      '@media screen and (max-width:950px)': {
        paddingBlockStart: 4,
      },
    } as TSO,

    COPY: {
      marginBlockStart: 1,
      color: text,
      fontSize: [1, 2],
    } as TSO,

    MEDIA: {
      inlineSize: '100%',
      flex: '1',
    } as TSO,

    FORM: {
      inlineSize: '100%',
      flex: '1',
      paddingInlineEnd: [1, 2, 6],
      paddingInlineStart: 1,
      paddingBlockStart: ['0px', 4, 5],
      paddingBlockEnd: ['0px', 4, 5],
      section: {
        marginBlockStart: 'unset',
      },
      form: {
        maxInlineSize: 'unset',
      },
      button: {
        inlineSize: '100%',
      },
    } as TSO,

    INLINE_FORM: {
      paddingBlockStart: 2,
    } as TSO,

    VIDEO: {
      flex: '1',
      position: 'relative',
      marginBlockStart: [3, 6],
      marginBlockEnd: [3, 6],
      paddingInlineStart: [1, 2, '0px'],
      paddingInlineEnd: [1, 2, '0px'],
    } as TSO,

    DESKTOP_ANIMATION: {
      inlineSize: '100%',
      margin: 'auto',
    } as TSO,

    MOBILE_ANIMATION: {
      inlineSize: '100%',
      margin: 'auto',
    } as TSO,

    CTA_CONTAINER: {
      display: 'flex',
      inlineSize: '100%',
      flexFlow: ['column wrap', 'row wrap'],
      alignItems: 'baseline',
      justifyContent: 'flex-start',
      marginBlockStart: 5,
      rowGap: 5,
    } as TSO,

    SECONDARY_CTA_CONTAINER: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignSelf: ['center', 'auto'],
    } as TSO,

    SECONDARY_CTA_LINKS: {
      '&:not(:first-of-type)': {
        marginBlockStart: 1,
      },
    } as TSO,
  };

  return Styles;
};
