import { propsValidator } from '@lib/utils';
import { H5, Block, Grid, Link, StarRating, H2 } from '#ui';
import ResponsiveMedia from '@components/ResponsiveMedia';
import { useStyles } from './sidebysidehero.styles';
import AppButton from '@components/AppButton';
import Lottie from 'react-lottie-player';
import MarkdownText from '@components/MarkdownText';
import Form from '@components/Form';

import type { SideBySideHeroProps as Props } from './types';
import type { FC } from 'react';

const SideBySideHero: FC<Props> = ({
  superheading,
  heading,
  copy,
  backgroundColor,
  responsiveMedia,
  ctasCollection,
  secondaryCtasCollection,
  primaryCtaColor,
  rating,
  ratingCopy,
  starRatingLink,
  animation,
  iconImage,
  form,
  ...entry
}) => {
  const valid = propsValidator(entry, { heading });
  if (!valid) return null;
  const desktopAnimation = animation?.desktopAsset?.url;
  const mobileAnimation = animation?.mobileAsset?.url;

  const hasAnimation = !!desktopAnimation || !!mobileAnimation;
  const wistiaVideo =
    !hasAnimation &&
    !responsiveMedia?.youtubeId &&
    !!responsiveMedia?.wistiaVideoUrl;
  const youtubeVideo = !wistiaVideo && !!responsiveMedia?.youtubeId;
  const mediaFile: boolean =
    !hasAnimation &&
    !wistiaVideo &&
    !youtubeVideo &&
    !!responsiveMedia?.desktopAsset?.url;
  const inLineForm = form?.variant === 'Inline Form';
  const stdForm =
    form?.variant !== 'Inline Form' &&
    !hasAnimation &&
    !responsiveMedia?.desktopAsset?.url;

  const Styles = useStyles({ backgroundColor, hasAnimation });

  return (
    <header sx={Styles.ROOT}>
      <Grid sx={Styles.CONTENT} noPadding wrap>
        <Block sx={Styles.CONTAINER}>
          {(!!superheading || !!rating) && (
            <div sx={Styles.SUPERHEADING_RATING}>
              {!!superheading && <H5 as="small">{superheading}</H5>}
              {!!rating && (
                <StarRating
                  backgroundColor={backgroundColor}
                  rating={rating}
                  ratingCopy={ratingCopy}
                  link={starRatingLink}
                />
              )}
            </div>
          )}
          {!!iconImage && (
            <ResponsiveMedia
              sx={{
                inlineSize: [
                  iconImage?.mobileAsset?.width,
                  iconImage?.desktopAsset?.width,
                ],
                ...Styles.LOGO,
              }}
              lazy={false}
              {...iconImage}
            />
          )}
          <H2 as="h1">{heading}</H2>
          {!!copy && (
            <MarkdownText styles={Styles.COPY} bkg={backgroundColor}>
              {copy}
            </MarkdownText>
          )}
          {(!!ctasCollection?.items.length ||
            !!secondaryCtasCollection?.items.length) && (
            <div sx={Styles.CTA_CONTAINER}>
              {!!ctasCollection?.items.length &&
                ctasCollection.items.map((cta, index) => (
                  <AppButton
                    key={index}
                    {...cta}
                    componentStyle={{
                      inlineSize: ['100%', 'fit-content'],
                      marginInlineEnd: [null, 5],
                    }}
                    color={primaryCtaColor}
                    contrast={backgroundColor}
                  >
                    {cta.ctaCopy}
                  </AppButton>
                ))}
              {!!secondaryCtasCollection?.items.length && (
                <div sx={Styles.SECONDARY_CTA_CONTAINER}>
                  {secondaryCtasCollection.items.map((item, key) => (
                    <Link
                      key={key}
                      href={item.destination || undefined}
                      sx={Styles.SECONDARY_CTA_LINKS}
                      withIcon
                      contrast={backgroundColor}
                      openInNewTab={item.openInNewTab}
                    >
                      {item.ctaCopy}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          )}
          {!!form && inLineForm && (
            <div sx={Styles.INLINE_FORM}>
              <Form order={0} {...form} />
            </div>
          )}
        </Block>
        {(!!desktopAnimation || !!mobileAnimation) && (
          <div sx={Styles.MEDIA}>
            {!!desktopAnimation && (
              <Block
                sx={{
                  display: mobileAnimation ? ['none', null, 'block'] : 'block',
                  ...Styles.DESKTOP_ANIMATION,
                }}
              >
                <Lottie play path={desktopAnimation} />
              </Block>
            )}
            {!!mobileAnimation && (
              <Block
                sx={{
                  display: desktopAnimation ? ['block', null, 'none'] : 'block',
                  ...Styles.MOBILE_ANIMATION,
                }}
              >
                <Lottie
                  loop={false}
                  play
                  path={mobileAnimation || desktopAnimation || undefined}
                />
              </Block>
            )}
          </div>
        )}
        {(wistiaVideo || youtubeVideo) && (
          <div sx={Styles.VIDEO}>
            <ResponsiveMedia lazy={false} {...responsiveMedia} />
          </div>
        )}
        {mediaFile && (
          <ResponsiveMedia
            sx={Styles.MEDIA}
            lazy={false}
            {...responsiveMedia}
          />
        )}
        {!!form && stdForm && (
          <div sx={Styles.FORM}>
            <Form order={0} {...form} />
          </div>
        )}
      </Grid>
    </header>
  );
};

export default SideBySideHero;
